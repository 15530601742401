import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import React from 'react'

function SearchRadio({title,value,onChange,options}) {
  return (
    <Box
        sx={{
          display: "flex",

          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >

        <Box
          sx={{
            backgroundColor: "lightgray",
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: "start",
            ml: 1.5,
            mt: 1,
            mb: 1,
            width: "100%",
            "& .MuiTypography-root": {
              fontFamily: "Cairo",
            },
          }}
          className="px-2 rounded"
        >
          <Typography>
            {/* {t('UNITS.has_duplicate')} */}
            {title}
          </Typography>

          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            sx={{
              width: "100%",
              "& .MuiTypography-root": {
                fontFamily: "Cairo",
              },
            }}
            // value={filters?.has_duplicate}
            value={value}
            onChange={onChange}
            // onChange={(e) => {
            //   setFilters({
            //     ...filters,
            //     has_duplicate: e?.target.value,
            //   })

            // }}
          >
            {options?.map(option=>{
                return <FormControlLabel
                value={option?.value}
                control={<Radio sx={{ fontFamily: "Cairo" }} />}
                label={option.label}
              />
            })}
            
            {/* <FormControlLabel
              value={0}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("UNITS.doesnt_have_duplicate")}
            />

            <FormControlLabel
              value={-1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("UNITS.all")}
            /> */}


          </RadioGroup>
        </Box>
      </Box>
  )
}

export default SearchRadio