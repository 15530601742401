
import React, { useState, useEffect } from "react";
import PopupForm from "../../../Components/PopupForm";
import { useTranslation } from "react-i18next";

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Cookies from "universal-cookie";
import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import CustomTextField from "../../../Components/CustomTextField";

const cookies = new Cookies();


export const viewObjectKeys = [
  "liters"
  , "source_geofence_name"
  , "destination_geofence_name"
  , "transaction_number"
  , "product_type"
  , "transaction_type"
  , "transaction_type_2"
  , "formatted_datetime"
  , "is_installed"
  , "is_subscribed"

]

export default function ViewFormContainer(props) {
  const [t] = useTranslation("common");
  const [transaction, setTransaction] = useState(null);



  useEffect(() => {

    setTransaction(props?.object)

  }, [props?.openForm, props?.object]);

  const specialFields = [
    {
      name: "is_installed",
      0: t("UNITS.not_installed"),
      1: t("UNITS.installed")

    },
    {
      name: "is_subscribed",
      0: t("UNITS.not_subscribed"),
      1: t("UNITS.subscribed")

    },
    {
      name: "has_duplicate",
      0: t("UNITS.doesnt_have_duplicate"),
      1: t("UNITS.has_duplicate")

    }
  ]

  const getValue = (viewObjectKey) => {
    let value = ""
    if (transaction?.hasOwnProperty(viewObjectKey)) {
      const specialField = specialFields.find(item => item?.name?.includes(viewObjectKey))
      if (specialField) {
        value = specialField[transaction[viewObjectKey]]
      }
      else {
        value = transaction[viewObjectKey]
      }


    }
    return value

  }

  return (
    <PopupForm
      open={props?.openForm}
      setOpen={props?.setOpenForm}
      title={
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Typography
            sx={{
              fontFamily: 'Cairo',
              marginLeft: '10px',
              fontSize:'22px',
              fontWeight:'800'
            }}

          >{t("UNITS.view_title")}
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Cairo',
              marginLeft: '10px',
              direction:'rtl'
            }}

          >{props?.object?.read_time}
          </Typography>


        </Box>}
      isFullScreen={false}

      content={<Box className="Container-fluid position-relative" sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} sx={{
          marginTop: '10px'
        }}>
          <Grid xs={6} sm={6} md={8} lg={8} sx={{
            padding: '0px'
          }}>
            <CustomTextField
              label={props?.unitState?.title}
              hasMultipleLine={true}
              value={props?.unitState?.text}
              haswidth={true}
              readOnly={true}
            />

          </Grid>
          <Grid xs={6} sm={6} md={4} lg={4} sx={{
            padding: '0px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: "center"
          }}>

            {props?.unitState?.icon == "error" ?
              <CancelIcon
                sx={{
                  fontSize: "60px",
                  fill: "#FF0000"
                }}
              /> :
              <CheckCircleIcon
                sx={{
                  fontSize: "60px",
                  fill: "green"
                }}
              />}


          </Grid>

          {props?.object?.transaction_number?.length && viewObjectKeys?.length ? viewObjectKeys?.map(viewObjectKey => {
            return <Grid xs={12} sm={6} md={4} lg={4} sx={{
              padding: '0px'
            }}>
              <CustomTextField
                label={t("UNITS." + viewObjectKey)}
                hasMultipleLine={true}
                value={getValue(viewObjectKey)}
                haswidth={true}
                readOnly={true}
              />

            </Grid>
          }) : null}

        </Grid>
      </Box>

      }
      footer={
        <Button
          variant="outlined"
          // spacing={2}
          sx={{
            width: "50% !important",
            // flexGrow: 1,
            minWidth: "80px !important",
            maxWidth: "80px !important",
            margin: 1,
            backgroundColor: "#d1d0d0",
            borderColor: "#ffffff",
            color: "#000000",
            boxShadow: "0 0 7px -2px white",
            //   color:SearchButtonTheme?.clear_button_text_color,

            "&:hover": {
              backgroundColor: "#e7a52a",
              borderColor: "#ffffff",
              color: "#fff",
              boxShadow: "0 0 7px 1px white",
              boxShadow: "0",
            },
            height: "35px",
            fontFamily: "Cairo-Bold",
          }}
          className="iconeFilterClear"
          color="secondary"
          onClick={() => {

            props?.setOpenForm(false);
          }}
        >
          {t("GLOBAL.close")}
        </Button>
      }
    />
  );
}
