import React, { useState, useEffect } from "react";
import Grid from "../Grid/Grid";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import DropDownGrid from "../Grid/Components/DropDownGrid";
import {
  Box,
  MenuItem,
  ListItemIcon,
  Tooltip,
  Typography,
  IconButton,
  Alert,
  AlertTitle
} from "@mui/material";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import Search from "./Search";
import transactionAPI from "../../Network/Transaction";
import Title from "./Title";
import { selectRoles } from "../../reduxStore/RolesReducer";
import CustomToggleStatus from "../../Components/CustomToggleStatus";
import CustomLargeTextView from "../../Components/CustomLargeTextView";
import DownloadIcon from '@mui/icons-material/Download';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import RouteIcon from '@mui/icons-material/Route';

import moment from "moment";
import branchAPI from "../../Network/Branch";
import CancelIcon from '@mui/icons-material/Cancel';
import FlagIcon from '@mui/icons-material/Flag';
import EditIcon from "@mui/icons-material/Edit";
import { selectTransactionFilter } from "../../reduxStore/SettingsReducer";
import transactionStatusAPI from "../../Network/TransactionStatus";
import VerifiedIcon from '@mui/icons-material/Verified';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import LocationOnIcon from '@mui/icons-material/LocationOn';



import { SiMicrosoftexcel } from 'react-icons/si'
import { BsFilePdfFill } from 'react-icons/bs'
import TransactionStops from "../Transactions/TransactionStops/TransactionStops";
import TransactionRoutes from "../Transactions/TransactionRoutes/TransactionRoutes";
import ViewFormContainer from "../Transactions/ViewForm/ViewFormContainer";

const cookies = new Cookies();

const todayStart = new Date();
const yesterday = new Date(todayStart);
yesterday.setDate(yesterday.getDate() - 1);
yesterday.setHours(0, 0, 0, 0);

const todayEnd = new Date();
todayEnd.setHours(23, 59, 59, 999);
export default function ReportDailyViolation() {
  const [t] = useTranslation("common");
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const transactionFilters = useSelector(selectTransactionFilter)
  const [branches, setBranches] = useState([])
  const [loadingBranches, setLoadingBranches] = useState(true)

  const [transactionProductTypes, setTransactionProductTypes] = useState([])
  const [loadingTransactionProductTypes, setLoadingTransactionProductTypes] = useState(true)

  const [pageCount, setPageCount] = useState(0);
  const [openFilterColumn, setOpenFilterColumn] = useState(false);
  const [openFilterColumnValue, setOpenFilterColumnValue] = useState([]);
  const [elementClicked, setElementClicked] = useState(null);

  const [filters, setFilters] = useState({

    unit: null,
    unit_id: "",
    sourceGeofence: null,
    source_geofence_id: "",
    destinationGeofence: null,
    destination_geofence_id: "",
    route: null,
    route_id: "",
    creation_date_from: moment().subtract(1, "day").startOf("day").format('YYYY-MM-DD') + " 00:00:00",
    creation_date_to: moment().endOf('day').format('YYYY-MM-DD') + " 23:59:59",
    creationDateFrom: yesterday,
    creationDateTo: todayEnd,
    export_excel: false,
    export_pdf: false,
    note: "",
    destination_geofence_name: "",
    source_geofence_name: "",
    all_time: 0,
    carrier:-1
  })
  const [reload, setReload] = useState(false)

  const [showAlert, setShowAlert] = useState(false)

  const [openForm, setOpenForm] = useState(false);
  const [openViewForm, setOpenViewForm] = useState(false);
  const [openUpdateForm, setOpenUpdateForm] = useState(false);
  const [openAuditForm, setOpenAuditForm] = useState(false)
  const [openCreateRouteForm, setOpenCreateRouteForm] = useState(false)
  const [selectedObject, setselectedObject] = useState(null);
  const [showStops, setShowStops] = useState(false)
  const [showRoutes, setShowRoutes] = useState(false)

  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const controller = new AbortController();
  const [rowsTotal, setRowsTotal] = useState(0);
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);

  const rolesRedux = useSelector(selectRoles)

  const [columns, setColumns] = useState([
    { field: "index_column", headerName: "ت", minWidth: 50, maxWidth: 60 },
    { field: "transaction_number", headerName: t("TRANSACTIONS.transaction_number"), minWidth: 110, maxWidth: 150, flex: 2 },
    {
      field: "formatted_datetime", headerName: t("TRANSACTIONS.date_time"), minWidth: 110, maxWidth: 150, flex: 2,
      renderHeader: (rows) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <Typography
            sx={{

              fontFamily: "Cairo-Medium",
              fontSize: 14
            }}

          >
            {t("TRANSACTIONS.date_time1")}
          </Typography>
          <Typography
            sx={{

              fontFamily: "Cairo-Medium",
              fontSize: 14
            }}

          >
            {t("TRANSACTIONS.date_time2")}
          </Typography>
        </Box>
      )
    },
    {
      field: "unit_registration_number", headerName: t("TRANSACTIONS.unit_registration_number"), minWidth: 110, maxWidth: 150, flex: 2,
      renderCell: (rows) => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Typography
              sx={{
                fontFamily: "Cairo-Bold",
                color: '#000000',
                fontWeight: 600,
                fontSize: '14px'
              }}

            >
              {rows?.row?.unit_registration_number}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Cairo-Bold",
                color: '#000000',
                fontWeight: 600,
                fontSize: '14px'
              }}

            >
              {rows?.row?.unit_province}
            </Typography>
          </Box>
        )
      }
    },
    { field: "driver_name", headerName: t("TRANSACTIONS.driver_name"), minWidth: 110, maxWidth: 150, flex: 2 },
    { field: "product_type", headerName: t("TRANSACTIONS.product_type"), minWidth: 110, maxWidth: 150, flex: 2 },
    { field: "liters", headerName: t("TRANSACTIONS.liters"), minWidth: 110, maxWidth: 150, flex: 2 },


    {
      field: "source_geofence_name", headerName: t("TRANSACTIONS.source_geofence"), minWidth: 110, maxWidth: 150, flex: 2,
      renderCell: (rows) => {
        return (
          <Typography
            sx={{
              fontFamily: "Cairo-Bold",
              color: '#000000',
              fontWeight: 600,
              fontSize: '14px'
            }}

          >
            {rows?.row?.source_geofence_name}
          </Typography>
        )
      }
    },
    {
      field: "destination_geofence_name", headerName: t("TRANSACTIONS.destination_geofence"), minWidth: 110, maxWidth: 150, flex: 2,
      renderCell: (rows) => {
        return (
          <Typography
            sx={{
              fontFamily: "Cairo-Bold",
              color: '#000000',
              fontWeight: 600,
              fontSize: '14px'
            }}

          >
            {rows?.row?.destination_geofence_name}
          </Typography>
        )
      }
    },



  ])

  const showUpdate = (has_update_role, transaction) => {

    return (
      has_update_role
      && !transaction?.destination_geofence_name
      && transaction?.enabled
      // && helpers.durationFromNow(transaction?.formatted_datetime, 30)
    )

  }
  const getRowClassName = (params) => {
    if (params?.row?.enabled === 0) {
      return 'highlighted-disabled-row'
    }
    else {
      if (params?.row?.transaction_status_id === 2) {
        return 'highlighted-auditing-row'
      }
      else if (params?.row?.transaction_status_id === 3) {
        return 'highlighted-invalid-row'
      }
      else if (params?.row?.transaction_status_id === 4) {
        return 'highlighted-valid-row'
      }
      else {
        return ''
      }
    }



  };

  const getResources = () => {


    branchAPI.getBranchList({
      params: {
        ...headerObject,
      }

    }).then(branchesResult => {
      if (branchesResult.status && branchesResult?.data?.data) {
        setBranches(branchesResult?.data?.data)
      }
      setLoadingBranches(false);
    }).catch(error => {
      console.log(error);
      setLoadingBranches(false);
    }).finally(() => {
      setLoadingBranches(false);
    })

    transactionAPI.transactionProductTypes({
      params: {
        ...headerObject,
      }

    }).then(transactionProductTypesResult => {
      if (transactionProductTypesResult.status && transactionProductTypesResult?.data?.data) {
        setTransactionProductTypes(transactionProductTypesResult?.data?.data)
      }
      setLoadingTransactionProductTypes(false);
    }).catch(error => {
      console.log(error);
      setLoadingTransactionProductTypes(false);
    }).finally(() => {
      setLoadingTransactionProductTypes(false);
    })



  }


  useEffect(() => {
    getResources()
    return () => {

    }
  }, [])

  useEffect(() => {
    const savedState = localStorage.getItem('dashboardFilters');

    if (!savedState) {
      // if (!transactionFilters) {
      loadData()
    }

  }, [pageNumber, pageSize, reload]);

  useEffect(() => {
    const savedState = localStorage.getItem('dashboardFilters');
    if (savedState) {
      const savedStateJson = {
        ...JSON.parse(savedState)
      }

      setFilters({
        ...filters,
        ...savedStateJson,
        creationDateFrom: new Date(savedStateJson?.creationDateFrom),
        creationDateTo: new Date(savedStateJson?.creationDateTo),

      })

      localStorage.clear();

      setTimeout(() => {
        // Code to execute after waiting for one second
        // ...
        setReload(!reload)
      }, 1000); // 1000 milliseconds = 1 second



    }
    // if (transactionFilters) {
    //   setFilters({
    //     ...filters,
    //     ...transactionFilters
    //   })

    //   dispatch(setTransactionFilters(null))
    //   setReload(!reload)
    // }
  }, [])

  const headerObject = {
    username: cookies.get("user_name") ? cookies.get("user_name") : null,
    user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
    account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
    account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
    authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
    sid: cookies.get("sid") ? cookies.get("sid") : null,
    baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
    id: cookies.get("id") ? cookies.get("id") : null,
  }
  const loadData = async (searchParams) => {

    // if (!filters?.destinationGeofence?.name) {
    //   setShowAlert(true)
    //   return
    // }
    // else {
    //   setShowAlert(false)
    // }

    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      let object = {
        ...headerObject,
        ...(filters?.export_excel || filters?.export_pdf ? {} :
          {
            page: pageNumber,
            page_size: pageSize,
          }),

        search: "",
        ...searchParams,
        ...filters,
        
        transaction_status_ids: [3],
        report_type: 14,
        report_value: null,
        fast:1
      };



      setLoading(true);

      const result = await transactionAPI.transactions({
        params: object,
        signal: controller?.signal,
      });
      setLoading(false);

      if (result.status && result?.data?.data) {


        if (filters?.export_excel) {
          setFilters({
            ...filters,
            export_excel: false
          })
          downloadExcel(result?.data?.data)
        }
        else if (filters?.export_pdf) {
          setFilters({
            ...filters,
            export_pdf: false
          })
          downloadPDF(result?.data?.data)
        }
        else {
          if (result?.data?.data?.data?.length > 0) {
            setRows(result?.data?.data?.data);
            setRowsTotal(result?.data?.data?.total_records);
            setPageCount(Math.ceil(result?.data?.data?.total_records / pageSize));
          }
          else {
            setRows([]);
            setRowsTotal(0);
            setPageCount(0);
          }
        }
      } else {
        setFilters({
          ...filters,
          export_pdf: false,
          export_excel: false
        })

        if (result?.data?.data?.message) {
          toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
        }
        else if (!result?.data?.error) {
          toast.error(t("NETWORK_MESSAGES.unknownError"));
          return;
        }
        if (result && result?.data && result?.data?.data?.message) {
          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }
        }
      }
      setLoading(false);
    } else {
      navigate("/redirect");
    }
  };

  const createRouteFunction = (obj) => {
    setselectedObject(obj)
    setOpenCreateRouteForm(true)
  }

  const auditFunction = (obj) => {
    setselectedObject(obj)
    setOpenAuditForm(true)
  }
  const createFunction = () => {
    setselectedObject(null);
    setOpenForm(true);
  };
  const showStopsFunction = (obj) => {
    setselectedObject(obj)
    setShowStops(true)
  }
  const showRoutesFunction = (obj) => {
    setselectedObject(obj)
    setShowRoutes(true)
  }
  const viewFunction = (obj) => {
    setselectedObject(obj)
    setOpenViewForm(true)
  }
  const UpdateFunction = (obj) => {
    setselectedObject(obj)
    setOpenUpdateForm(true)
  }
  const exportReport = (object) => {
    confirmExportReport(object)

    // swal({
    //   title: t('GLOBAL.are_you_sure'),
    //   text: t('TRANSACTIONS.start_export_report'),
    //   icon: 'info',
    //   buttons: [t('GLOBAL.no'), t('GLOBAL.yes')],
    //   dangerMode: true,
    // }).then(async (updateWithWialonLink) => {

    //   if (updateWithWialonLink) {
    //     confirmExportReport(object)
    //   } else {
    //     // alert('canceled')
    //   }
    // })


  }

  const createReport = async (object) => {
    setLoading(true)
    let readyToSendObject = {}

    let params = {
      ...headerObject
    };

    let result = null;
    if (object && object?.id) {
      readyToSendObject["transaction_id"] = object?.id;

      try {
        result = await transactionAPI.addCreatedgReport({
          data: readyToSendObject,
          params: params,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });
        if (result?.status && result?.data?.status) {
          toast.success("تم انشاء تقرير")
          setLoading(false)
        } else {

          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }

          else {
            setLoading(false)
            toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
          }
        }

      } catch (error) {
        setLoading(false)

        toast.error(error.message);
      }
    }
    setLoading(false)
  }

  const confirmExportReport = async (object) => {
    setLoading(true)
    let readyToSendObject = {}

    let params = {
      ...headerObject
    };

    let result = null;
    if (object && object?.id) {
      readyToSendObject["transaction_id"] = object?.id;

      try {
        result = await transactionAPI.addPenddingReport({
          data: readyToSendObject,
          params: params,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });
        if (result?.status && result?.data?.status) {




          const fileResult = await transactionAPI.downloadPenddingReport({
            params: {
              ...params,
              pending_transaction_report_id: result?.data?.data?.id
            }
          })

          if (fileResult?.status && fileResult?.data?.status) {

            downloadPDF(fileResult?.data?.data?.report_path, object)
          }
          else {
            toast.error(fileResult?.data?.data?.message?.text ? fileResult?.data?.data?.message?.text : fileResult?.data?.data?.message)
            setLoading(false)
          }


        } else {


          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }

          else {

            toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
          }
        }

      } catch (error) {
        setLoading(false)

        toast.error(error.message);
      }
    }
  }
  const downloadPDF = async (data, transaction) => {
    const result = await transactionAPI.downloadPdf({
      params: {
        ...headerObject,
        fileName: data?.result
      }
    })
    setLoading(false)
    if (result.status && result?.data) {
      const url = window.URL.createObjectURL(new Blob([result?.data]));
      const link = document.createElement('a');
      link.href = url;
      let reportName = t('SIDEMENU.report_daily_violation')
      
      link.setAttribute('download', reportName + '.' + data?.type);
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up the URL object
      window.URL.revokeObjectURL(url);
    }

  }
  const exportFunction = () => {

    setFilters({
      ...filters,
      export_excel: true
    })
    setReload(!reload)
  }
  const exportPDF = () => {
    setFilters({
      ...filters,
      export_pdf: true
    })
    setReload(!reload)
  }

  const clearFilterFunction = () => {
    setFilters({
      unit: null,
    unit_id: "",
    sourceGeofence: null,
    source_geofence_id: "",
    destinationGeofence: null,
    destination_geofence_id: "",
    route: null,
    route_id: "",
    creation_date_from: moment().subtract(1, "day").startOf("day").format('YYYY-MM-DD') + " 00:00:00",
    creation_date_to: moment().endOf('day').format('YYYY-MM-DD') + " 23:59:59",
    creationDateFrom: yesterday,
    creationDateTo: todayEnd,
    export_excel: false,
    export_pdf: false,
    note: "",
    destination_geofence_name: "",
    source_geofence_name: "",
    all_time: 0,
    carrier: -1
    })
  }
  const searchFunction = () => {
    console.log(filters);

    setReload(!reload)
    setPageNumber(1)
  };

  const downloadExcel = async (fileName) => {
    const result = await transactionAPI?.downloadExcel({
      params: {
        ...headerObject,
        fileName: fileName
      }
    })
    if (result.status && result?.data) {
      var _url = window.URL.createObjectURL(result?.data);
      window.open(_url, "_blank").focus(); // window.open + focus
    }
  }

  const getTitleAndSecondaryTitle = () => {
    let title = t('SIDEMENU.report_daily_violation')


    let secondaryTitle = null
    if (filters?.report_date_only_formated) {
      secondaryTitle = (t('TRANSACTIONS.report_date') + " : " + filters?.report_date_only_formated)
    }


    return {
      title: title,
      secondaryTitle: secondaryTitle
    }
  }

  
  return (
    <Box
      sx={{

        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "start",
        overflowX: "hidden",
        "& .MuiChip-label": {
          fontFamily: "Cairo-Medium",
        },
      }}
    >
      {showStops ? (
        <TransactionStops

          object={selectedObject}
          openForm={showStops}
          setOpenForm={setShowStops}
          loadData={loadData}
        />
      ) : null}
      {showRoutes ? (
        <TransactionRoutes
          object={selectedObject}
          openForm={showRoutes}
          setOpenForm={setShowRoutes}
          loadData={loadData}
        />
      ) : null}

      {openViewForm && selectedObject ? (
        <ViewFormContainer
          object={selectedObject}
          openForm={openViewForm}
          setOpenForm={setOpenViewForm}
        />
      ) : null}


      <Title
        title={getTitleAndSecondaryTitle()?.title}
        secondaryTitle={getTitleAndSecondaryTitle()?.secondaryTitle}
      />
      {/* {showAlert ?
        <Alert sx={{
          fontFamily: 'Cairo',
          width:'100%'
        }} severity="warning">
          <AlertTitle sx={{
            fontFamily: 'Cairo'
          }}>تنبيه</AlertTitle>
          يجب اختيار  <strong>نقطة تفريغ</strong> للبحث عن البيانات
        </Alert>
        : null
      } */}
      <Grid
        rows={rows}
        multipleExports={rolesRedux?.export_transaction?.value ?
          [
            { icon: <SiMicrosoftexcel color="green" size={"25"} />, name: t('TRANSACTIONS.exportExcel'), key: 'report1', onClick: () => { exportFunction() } },
            { icon: <BsFilePdfFill color="red" size={"25"} />, name: t('TRANSACTIONS.exportPDF'), key: 'report4', onClick: () => { exportPDF() } },
          ] : []
        }
        fixHeight={'calc(100vh - 300px)'}
        getRowClassName={getRowClassName}
        hasExport={false}
        exportFunction={exportFunction}
        columns={columns}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        loading={loading}
        handlePageChange={handlePageChange}
        rowsTotal={rowsTotal}
        checkScreenSize={screenwidth}
        pageCount={pageCount}
        openFilterColumn={openFilterColumn}
        elementClicked={elementClicked}
        setOpenFilterColumn={setOpenFilterColumn}
        setColumnFilterValue={setOpenFilterColumnValue}
        columFilterValue={openFilterColumnValue}

        clearFunction={clearFilterFunction}
        searchFunction={searchFunction}

        hasCreate={false}
        creatFunction={createFunction}

        hasColumnsHidShow={false}
        setColumns={setColumns}

        filterChilds={
          <Search
            filters={filters}
            setFilters={setFilters}
            loadingBranches={loadingBranches}
            branches={branches}
            loadingTransactionProductTypes={loadingTransactionProductTypes}
            transactionProductTypes={transactionProductTypes}
            showBySequence={
              {
                // branches: 1,
                branches_types: 1,
                destinationGeofence: 2,
                productType: 3,
                dateRange: 4,
                carrier: 5
              }
            }


          />
        }
      />

    </Box>
  );
}
