import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    roles: {

        view_user: {
            id: 1,
            value: false
        },
        add_user: {
            id: 2,
            value: false
        },
        update_user: {
            id: 3,
            value: false
        },
        delete_user: {
            id: 4,
            value: false
        },
        view_disable_user: {
            id: 5,
            value: false
        },
        view_geofence: {
            id: 6,
            value: false
        },
        add_geofence: {
            id: 7,
            value: false
        },
        update_geofence: {
            id: 8,
            value: false
        },
        delete_geofence: {
            id: 9,
            value: false
        },
        view_disable_geofence: {
            id: 10,
            value: false
        },
        view_unit: {
            id: 11,
            value: false
        },
        add_unit: {
            id: 12,
            value: false
        },
        update_unit: {
            id: 13,
            value: false
        },
        delete_unit: {
            id: 14,
            value: false
        },
        view_disable_unit: {
            id: 15,
            value: false
        },
        refresh_unit_from_wialon: {
            id: 16,
            value: false
        },
        search_unit_in_wialon: {
            id: 17,
            value: false
        },
        view_route: {
            id: 18,
            value: false
        },
        add_route: {
            id: 19,
            value: false
        },
        update_route: {
            id: 20,
            value: false
        },
        delete_route: {
            id: 21,
            value: false
        },
        view_disable_route: {
            id: 22,
            value: false
        },
        view_transaction: {
            id: 23,
            value: false
        },
        add_transaction: {
            id: 24,
            value: false
        },
        update_transaction: {
            id: 25,
            value: false
        },
        delete_transaction: {
            id: 26,
            value: false
        },
        view_disable_transaction: {
            id: 27,
            value: false
        },
        view_notification: {
            id: 28,
            value: false
        },
        add_notification: {
            id: 29,
            value: false
        },
        update_notification: {
            id: 30,
            value: false
        },
        delete_notification: {
            id: 31,
            value: false
        },
        view_disable_notification: {
            id: 32,
            value: false
        },
        add_pending_transaction_id: {
            id: 33,
            value: false
        },
        view_master: {
            id: 34,
            value: false
        },
        add_master: {
            id: 35,
            value: false
        },
        update_master: {
            id: 36,
            value: false
        },
        delete_master: {
            id: 37,
            value: false
        },
        view_disable_master: {
            id: 38,
            value: false
        },
        export_geofence: {
            id: 39,
            value: false
        },
        export_route: {
            id: 40,
            value: false
        },
        export_transaction: {
            id: 41,
            value: false
        },
        view_all_resources: {
            id: 42,
            value: false
        },
        update_user_roles: {
            id: 43,
            value: false
        },
        view_activity: {
            id: 44,
            value: false
        },
        export_transaction_report: {
            id: 45,
            value: false
        },
        export_unit: {
            id: 46,
            value: false
        },
        view_transaction_audit: {
            id: 47,
            value: false
        },
        add_transaction_audit: {
            id: 48,
            value: false
        },
        update_transaction_audit: {
            id: 49,
            value: false
        },
        delete_transaction_audit: {
            id: 50,
            value: false
        },
        view_disable_transaction_audit: {
            id: 51,
            value: false
        },
        enable_geofence: {
            id: 52,
            value: false
        },
        update_geofence_name: {
            id: 53,
            value: false
        },
        update_geofence_code: {
            id: 54,
            value: false
        },
        update_user_units: {
            id: 55,
            value: false
        },
        view_main_unit: {
            id: 56,
            value: false
        },
        add_main_unit: {
            id: 57,
            value: false
        },
        update_main_unit: {
            id: 58,
            value: false
        },
        delete_main_units: {
            id: 59,
            value: false
        },
        view_disable_main_units: {
            id: 60,
            value: false
        },
        refresh_main_unit_from_wialon: {
            id: 61,
            value: false
        },
        search_main_unit_in_wialon: {
            id: 62,
            value: false
        },
        view_mobile_user: {
            id: 63,
            value: false
        },
        view_transaction_no_unit: {
            id: 64,
            value: false
        },
        full_update_main_unit: {
            id: 65,
            value: false
        },
        view_geofence_group: {
            id: 66,
            value: false
        },
        add_geofence_group: {
            id: 67,
            value: false
        },
        update_geofence_group: {
            id: 68,
            value: false
        },
        delete_geofence_group: {
            id: 69,
            value: false
        },
        view_disable_geofence_group: {
            id: 70,
            value: false
        },
        update_user_geofence_groups: {
            id: 71,
            value: false
        },
        edit_and_update_main_unit_plate: {
            id: 72,
            value: false
        },
        show_main_units_activites: {
            id: 73,
            value: false
        },
        view_short_transactions: {
            id: 74,
            value: false
        }
    },
};
export const RolesReducer = createSlice({
    name: "roles",
    initialState: initialState,
    reducers: {
        setRolesRedux: (state, action) => {
            state.roles = action.payload;
        },
    },
});

export const { setRolesRedux } =
    RolesReducer.actions;

export const selectRoles = state => {

    return state?.rolesData?.roles
};
export default RolesReducer.reducer;
