import React from 'react'
import { useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import $ from 'jquery'
import Cookies from "universal-cookie";
import { useNavigate } from 'react-router-dom'
import Preloading from '../Pages/Preload/Preloading';
import profileAPI from '../Network/Profile/index'
const cookies = new Cookies();

function LandingPage(props) {
    const search = useLocation().search;
    const sid = new URLSearchParams(search).get('sid');
    const baseUrl = new URLSearchParams(search).get('baseUrl');
    const hostUrl = new URLSearchParams(search).get('hostUrl');

    const user = new URLSearchParams(search).get('user');
    const authHash = new URLSearchParams(search).get('authHash');
    const [units, setUnits] = useState([])
    const [activeSession, setActiveSession] = useState(false)
    const navigate = useNavigate()
    // Login to server using entered username and password

    useEffect(() => {

        cookies.set('baseUrl', baseUrl, { path: '/' });
        cookies.set('hostUrl', hostUrl, { path: '/' });
        cookies.set('authHash', authHash, { path: '/' });


        if (sid && baseUrl) {
            document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            // loginWithHash()
            $.ajax({
                type: "GET",
                enctype: "application/json",
                processData: !1,
                contentType: !1,
                crossDomain: true,
                dataType: "jsonp",
                // url: baseUrl + "/avl_evts?sid=" + sid,
                url: baseUrl + '/wialon/ajax.html?svc=core/duplicate&params={"operateAs":"","continueCurrentSession":true}&sid=' + sid,
                success: function (data) {
                    console.log("duplicate", data);
                    if (data?.eid && data?.user?.id) {


                        cookies.set('sid', data?.eid, { path: '/' });
                        cookies.set('user_id', data?.user?.id, { path: '/' });
                        cookies.set('user_name', data?.user?.nm, { path: '/' });

                        let userData = {
                            user_name: data?.user?.name,
                            user_id: data?.user?.id,

                        }
                        $.ajax({
                            type: "GET",
                            enctype: "application/json",
                            processData: !1,
                            contentType: !1,
                            crossDomain: true,
                            dataType: "jsonp",
                            url: baseUrl + '/wialon/ajax.html?svc=core/search_item&params={"id":' + data?.user?.bact + ',"flags":1}&sid=' + data?.eid,
                            success: async function (dataDetails) {
                                
                                if (dataDetails?.item?.nm && dataDetails?.item?.id) {

                                    cookies.set('account_id', dataDetails?.item?.id, { path: '/' });
                                    cookies.set('account_name', dataDetails?.item?.nm, { path: '/' });
                                    cookies.set('active_session', 'yes', { path: '/' });

                                    $.ajax({
                                        type: "GET",
                                        enctype: "application/json",
                                        processData: !1,
                                        contentType: !1,
                                        crossDomain: true,
                                        dataType: "jsonp",
                                        url: baseUrl + '/wialon/ajax.html?svc=core/search_items&params={"spec":{"itemsType":"avl_resource","propName":"sys_name","propValueMask":"*","sortType":"rsys_name","propType":"property"},"force":0,"flags":1,"from":0,"to":0}&sid='+data?.eid,
                                        // url: baseUrl + '/wialon/ajax.html?svc=core/search_item&params={"id":' + data?.user?.bact + ',"flags":1}&sid=' + data?.eid,
                                        success: async function (accountsData) {
                                           console.log("accountsData",accountsData?.items);
                                            if (accountsData?.items?.length) {

                                                const ids = accountsData?.items.map(item => item.id).join(',');
                                               
                                                cookies.set('accessible_accounts_ids', ids, { path: '/' });

                                                const result = await checkUserHasPhone({
                                                    account_id: data?.item?.id,
                                                    account_name: data?.item?.nm,
                                                    accessible_accounts_ids: ids,
                                                    ...userData,
                                                })
                                                if (result)
                                                    setActiveSession(true)
                                                else {
                                                    cookies.set('active_session', 'no', { path: '/' });
                                                    navigate('/redirect')
                                                    setActiveSession(false)
                                                }
                                            }
                                            else if (data?.error) {

                                                cookies.set('active_session', 'no', { path: '/' });
                                                navigate('/redirect')
                                                setActiveSession(false)
                                            }
                                        }
                                    })

                                }
                                else if (data?.error) {

                                    cookies.set('active_session', 'no', { path: '/' });
                                    navigate('/redirect')
                                    setActiveSession(false)
                                }
                            }
                        })

                    }
                    else if (data?.error) {

                        cookies.set('active_session', 'no', { path: '/' });
                        setActiveSession(false)
                        navigate('/redirect')
                    }

                }
            })
        } else {
            navigate('/redirect')
        }

        // setTimeout(()=>{
        //     setActiveSession(true)
        // },3000)


        return () => { } // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])



    const checkUserHasPhone = async (object) => {
        if (cookies.get('active_session') == 'yes') {
            let data = null

            let params = {
                username: cookies.get("user_name"),
                user_id: cookies.get("user_id"),
                account_name: cookies.get("account_name"),
                account_id: cookies.get("account_id"),
                sid: cookies.get("sid"),
                baseurl: cookies.get("baseUrl"),
                accessible_accounts_ids: cookies.get("accessible_accounts_ids")

            };

            try {


                data = await profileAPI.Profile({
                    params: params
                });

                if (data && data?.status && data?.data?.data?.id) {

                    cookies.set('id', data?.data?.data?.id, { path: '/' });
                    return true

                }

            } catch (e) {
                console.log(e?.message)
            }
            return false
        } else {
            navigate('/redirect')
        }
    }


    if (activeSession) {
        //  <Outlet/>
        navigate('/system/dashboard')

    } else
        return (<Preloading />)
}

export default LandingPage